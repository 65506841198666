define("@ember/-internals/views/lib/views/states/pre_render", ["exports", "@ember/-internals/views/lib/views/states/default"], function (_exports, _default3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var preRender = Object.assign({}, _default3.default);
  var _default2 = _exports.default = Object.freeze(preRender);
});
